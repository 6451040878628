import React from 'react'
import { getFormattedDate } from 'util/date'
// Components
import DiskHeader from 'components/Sections/Disk/DiskHeader'
import DiskSongs from 'components/Sections/Disk/DiskSongs'
import DiskFooter from './DiskFooter'
// Models
import { IDisk } from 'models/Disk'

type DiskContainerProps = {
    disk: IDisk
}
const DiskContainer: React.FC<DiskContainerProps> = ({ disk }) => {
    const date = getFormattedDate(disk.release_date)
    return (
        <>
            <DiskHeader disk={disk} date={date} />
            <DiskSongs songs={disk.songs} />
            <DiskFooter disk={disk} />
        </>
    )
}

export default DiskContainer

// extracted by mini-css-extract-plugin
export var disk_header = "DiskHeader-module--disk_header--sGtvN";
export var disk_background = "DiskHeader-module--disk_background--Pw9zW";
export var disk_cover_and_info = "DiskHeader-module--disk_cover_and_info--ZCipM";
export var album_badge = "DiskHeader-module--album_badge--z31RE";
export var single_badge = "DiskHeader-module--single_badge--tqj7f";
export var disk_cover = "DiskHeader-module--disk_cover--JIysf";
export var disk_info = "DiskHeader-module--disk_info--triVu";
export var disk_title = "DiskHeader-module--disk_title--H8V52";
export var disk_details = "DiskHeader-module--disk_details--pdm9C";
export var disk_authors = "DiskHeader-module--disk_authors--z-TQk";
export var icon = "DiskHeader-module--icon--Udp-H";
import React from 'react'
// Components
import SocialIcons from 'components/UIElements/SocialIcons'
import Share from 'components/UIElements/Share'
// Models
import { IDisk } from 'models/Disk'
// Styles
import * as styles from './DiskFooter.module.css'

type DiskFooterProps = {
    disk: IDisk
}

const DiskFooter: React.FC<DiskFooterProps> = ({ disk }) => {
    const { youtube, youtubeMusic, spotify, amazonMusic, deezer } = disk
    return (
        <footer>
            <SocialIcons
                className={styles.disk_streaming}
                youtube={youtube}
                youtubeMusic={youtubeMusic}
                spotify={spotify}
                deezer={deezer}
                amazonMusic={amazonMusic}
            />
            <Share message={disk.name} />
        </footer>
    )
}

export default DiskFooter

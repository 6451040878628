import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDiskDuration } from 'util/time'
// Models
import { IDisk } from 'models/Disk'
// Styles
import * as styles from './DiskHeader.module.css'

type DiskHeaderProps = {
    disk: IDisk
    date: string
}

const DiskHeader: React.FC<DiskHeaderProps> = ({ disk, date }) => {
    const { name, songs, authors, type, cover } = disk
    const duration = songs && getDiskDuration(songs)
    return (
        <header className={styles.disk_header}>
            <GatsbyImage
                className={styles.disk_background}
                image={cover.gatsbyImageData}
                alt={name}
            />
            <figure className={styles.disk_cover_and_info}>
                <GatsbyImage
                    className={styles.disk_cover}
                    image={cover.gatsbyImageData}
                    alt={name}
                />
                <div className={styles.disk_info}>
                    {type[0] === 'album' && (
                        <span className={styles.album_badge}>Álbum</span>
                    )}
                    {type[0] === 'single' && (
                        <span className={styles.single_badge}>Sencillo</span>
                    )}
                    <h1 className={styles.disk_title}>{name}</h1>
                    <div className={styles.disk_details}>
                        <p className={styles.disk_authors}>
                            Por{' '}
                            {authors.map((a, index) => (
                                <React.Fragment key={a.slug}>
                                    <Link to={`/artists/${a.slug}`}>
                                        {a.name}
                                    </Link>
                                    {index < authors.length - 1 ? ', ' : ''}
                                </React.Fragment>
                            ))}
                        </p>
                        {songs?.length && (
                            <span>
                                <FontAwesomeIcon
                                    className={styles.icon}
                                    icon={['fas', 'music']}
                                />
                                {songs.length > 1
                                    ? `${songs.length} canciones`
                                    : `${songs.length} canción`}
                            </span>
                        )}
                        <span>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={['far', 'calendar-alt']}
                            />
                            {date}
                        </span>
                        <span>
                            <FontAwesomeIcon
                                className={styles.icon}
                                icon={['far', 'clock']}
                            />
                            {duration ? duration : 'Desconocido'}
                        </span>
                    </div>
                </div>
            </figure>
        </header>
    )
}

export default DiskHeader

import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSongDuration } from 'util/time'
// SVG
import Translation from 'svg/translation'
// Models
import { ISong } from 'models/Song'
// Styles
import * as styles from './DiskSongs.module.css'

type DiskSongsProps = {
    songs: ISong[] | undefined
}

const DiskSongs: React.FC<DiskSongsProps> = ({ songs }) => {
    if (!songs) return <></>
    return (
        <section className={styles.songs_list}>
            <div className={styles.song_item_guide}>
                <span className={styles.song_number}>
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={['fas', 'hashtag']}
                    />
                </span>
                <span className={styles.song_name}>Canción</span>
                <span className={styles.song_duration}>
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={['far', 'clock']}
                    />
                </span>
            </div>
            {songs.map((song, index) => {
                const duration = getSongDuration(song)
                return (
                    <Link
                        to={`/songs/${song.slug}`}
                        key={song.slug}
                        className={styles.song_item}
                    >
                        <span className={styles.song_number}>{index + 1}</span>
                        <span className={styles.song_name}>
                            {song.name}
                            {song.lyric_es && (
                                <Translation className={styles.translated} />
                            )}
                            {song.cc_es && (
                                <FontAwesomeIcon
                                    className={styles.cc}
                                    icon={['far', 'closed-captioning']}
                                />
                            )}
                        </span>
                        <span className={styles.song_duration}>{duration}</span>
                    </Link>
                )
            })}
        </section>
    )
}

export default DiskSongs

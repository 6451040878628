import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { getFormattedDate } from 'util/date'
// Components
import Layout from 'components/Layout'
import DiskContainer from 'components/Sections/Disk/DiskContainer'
// Models
import { IOptionalMetaProps } from 'models/SEO'
import { IDisk } from 'models/Disk'

type DiskPageProps = {
    disk: IDisk
}

type Context = {
    slug: string
}

const DiskPage: React.FC<PageProps<DiskPageProps, Context>> = ({ data }) => {
    const disk = data.disk
    const { name, info, release_date, cover } = disk
    const date = getFormattedDate(release_date)
    const seo: IOptionalMetaProps = {
        title: name,
        description: info
            ? info.raw
            : `Página del disco "${name}" lanzado el ${date}.`,
        image: cover.file?.url,
    }
    return (
        <Layout seo={seo}>
            <DiskContainer disk={disk} />
        </Layout>
    )
}

export default DiskPage

export const pageQuery = graphql`
    query DiskPage($slug: String!) {
        disk: contentfulDisks(slug: { eq: $slug }) {
            name
            slug
            hachi
            release_date
            type
            cover {
                gatsbyImageData(layout:CONSTRAINED)
                file {
                    url
                }
            }

            amazonMusic
            deezer
            spotify
            youtubeMusic

            songs {
                name
                slug
                duration
                lyric_es {
                    raw
                }

                cc_es {
                    file {
                        url
                    }
                }
            }

            authors {
                name
                slug
            }
        }
    }
`
